<template>
  <form @submit.prevent="addLog">
    <modal-card :title="'Create new log entry'" :processing="processing">
      <b-field label="Message *">
        <b-input
          v-model="message"
          :disabled="processing"
          :placeholder="'Enter your message here'"
          type="textarea"
          expanded
          required
        />
      </b-field>

      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="!validForm || processing"
        type="submit"
        class="button is-success"
      >
        Submit
      </button>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "AddLogModal",
  props: {
    metadata: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      message: "",
      processing: false
    };
  },
  computed: {
    validForm() {
      if (this.$_.isEmpty(this.message)) return false;
      return true;
    }
  },
  methods: {
    addLog() {
      this.processing = true;
      this.$store
        .dispatch("log/add", {
          metadata: this.$_.merge({}, this.metadata, { message: this.message })
        })
        .then(result => {
          this.processing = true;
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            queue: false
          });
          this.$emit("close");
        })
        .catch(error => {
          this.processing = true;
          this.$toast.open({
            message: error.message,
            position: "is-bottom",
            type: "is-danger",
            queue: false
          });
        });
    }
  }
};
</script>
